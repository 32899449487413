import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: { customProperties: true },
        themes: {
            light: {
                primary: {
                    base: '7dafca',
                    darken1: '#4C4C4C',
                    darken2: '#808080',
                    darken3: '#fff',
                },
                secondary: '#ffffff', // #FFCDD2
                accent: '#ffffff', // #3F51B5
                button: '#29324a',
            },
        },
    },
});
