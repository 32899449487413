<template>
    <div></div>
</template>

<script>
// @ is an alias to /src
import { v4 as uuidv4 } from 'uuid';

export default {
    components: {},
    name: 'Home',

    computed: {
        showHomePage: function showHomePageCheck() {
            let bool = false;
            if (
                !this.$cookies.isKey('municipality') ||
                parseInt(this.$route.params.redirect, 10) === 0
            ) {
                bool = true;
            }
            return bool;
        },
    },

    mounted() {
        // add UUID to user if it does not exist in cookies.
        if (!this.$cookies.isKey('uuid')) {
            this.$cookies.set('uuid', uuidv4(), '6y');
        }

        // If the route is called when clicking the logo in the navigation bar
        // then do not automatically redirect to municipalicy view.
        console.log('Home is mounted');
        console.log(this.showHomePage);

        if (!this.showHomePage) {
            console.log('redirecting ...');
            this.$router.push({
                name: 'municipality_home',
                params: { municipalityId: this.$cookies.get('municipality') },
            });
        } else {
            this.$router.push({
                name: 'landing_page',
                params: { municipalityId: this.$cookies.get('municipality') },
            });
        }
    },
};
</script>
