import axios from 'axios';

function constructHeader() {
    const accessToken = localStorage.getItem('access_token');
    const config = {
        headers: {
            Authorization: `Bearer ${accessToken}`,
            Accept: 'application/json',
            Cookie: 'sprogtrap2222pen20_session=value;',
            // add access-control-allow-origin: * to the response header
            'Access-Control-Allow-Origin': '*',

        },
    };

    return config;
}

async function getAvailableMunicipalities(body) {
    const apiUrl = process.env.VUE_APP_API_URL;
    const Url = `${apiUrl}/api/municipalities/`;
    return axios.get(Url, body);
}

async function getMunicipality(id, body) {
    const config = constructHeader();
    const apiUrl = process.env.VUE_APP_API_URL;
    const Url = `${apiUrl}/api/municipality/${id}`;
    return axios.get(Url, body, config);
}

async function getCourse(id, body) {
    const config = constructHeader();
    const apiUrl = process.env.VUE_APP_API_URL;
    const Url = `${apiUrl}/api/course/${id}`;
    return axios.get(Url, body, config);
}

// async function getCourse(id) {
//     const config = constructHeader();
//     const apiUrl = process.env.VUE_APP_API_URL;
//     const Url = `${apiUrl}/api/course/${id}`;
//     return axios.get(Url, config);
// }

async function postLog(body) {
    const apiUrl = process.env.VUE_APP_API_URL;
    const Url = `${apiUrl}/api/logInteraction`;
    return axios.post(Url, body);
}

async function getCustomization() {
    const apiUrl = process.env.VUE_APP_API_URL;
    const Url = `${apiUrl}/api/customization`;
    return axios.get(Url);
}

async function getTexts() {
    const apiUrl = process.env.VUE_APP_API_URL;
    const Url = `${apiUrl}/api/texts`;
    return axios.get(Url);
}

async function getSettings() {
    const apiUrl = process.env.VUE_APP_API_URL;
    const Url = `${apiUrl}/api/settings`;
    return axios.get(Url);
}

async function login(emailInput, passwordInput) {
    const apiUrl = process.env.VUE_APP_API_URL;
    const Url = `${apiUrl}/api/login`;
    const parameters = {
        email: emailInput,
        password: passwordInput,
    };

    return axios.get(Url, { params: parameters });
}

async function me() {
    const config = constructHeader();
    const apiUrl = process.env.VUE_APP_API_URL;
    const Url = `${apiUrl}/api/me`;
    return axios.get(Url, config);
}

async function auth(sessionToken) {
    const Url = process.env.VUE_APP_AUTH_URL;
    return axios.post(Url, { session: sessionToken }, {});
}

export {
    postLog,
    getAvailableMunicipalities, getMunicipality, getCourse, getCustomization,
    getTexts, getSettings, login, me, auth,
};
