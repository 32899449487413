<template>
    <v-app class="app-size" v-if="!isLoading">
        <v-app-bar app class="appbarStyle" height="70px" elevation="2">
            <div class="d-flex align-center">
                <a :href="this.homeUrl">
                    <v-img
                        alt="Huset Pia Thomsen Logo"
                        class="shrink mr-2"
                        contain
                        :src="constructFileUrl('/logo/logo.svg')"
                        transition="scale-transition"
                        width="300"
                    />
                </a>
            </div>
            <v-spacer></v-spacer>

            <v-btn
                :href="$store.state.texts.appbar_link1_url"
                text
                class="menu-button"
                large
            >
                {{ $store.state.texts.appbar_link1_text }}
            </v-btn>

            <v-btn
                href='https://sprogtrappen.dk'
                text
                class="menu-button-solid"
                large
            >
                Gå til sprogtrappen
            </v-btn>

        </v-app-bar>

        <v-main max-width="1080" fluid>
            <router-view></router-view>
        </v-main>
        <footer>
            <cookie-law
                :buttonText="$store.state.texts.cookie_button_text"
                :message="$store.state.texts.cookie_message_text"
                theme="base rounded"
                buttonClass="cookieButton"
                :buttonLinkNewTab="true"
                :buttonLink="$store.state.texts.cookie_more_info_button_link"
                :buttonLinkText="
                    $store.state.texts.cookie_more_info_button_text
                "
            ></cookie-law>
        </footer>
    </v-app>
</template>

<script>
import CookieLaw from 'vue-cookie-law';
import {
    getCustomization,
    getTexts,
    getSettings,
    auth,
} from './plugins/api_requests';
import { constructUrlForStorage } from './plugins/helpers';

export default {
    components: { CookieLaw },
    name: 'App',

    data: () => ({
        homeUrl: `${process.env.VUE_APP_APP_URL}/0`,
        isLoadingCustomization: true,
        isLoadingTexts: true,
        isLoadingSettings: true,
        isLoadingAuth: true,
    }),

    beforeMount() {
        this.getCustomizationFromAPI();
        this.getTextsFromAPI();
        this.getSettingsFromAPI();

        // console.log the url parameter session
        const urlParams = new URLSearchParams(window.location.search);
        const session = urlParams.get('session');
        const endRedirect = urlParams.get('endRedirect');

        // if session is not null, set the session in the store
        if (session) {
            this.$store.commit('setSession', session);
        }
        this.getAuth(endRedirect);
        this.isLoadingAuth = false;
    },

    computed: {
        isLoading: function isEverythingLoaded() {
            if (
                this.isLoadingCustomization ||
                this.isLoadingTexts ||
                this.isLoadingSettings || this.isLoadingAuth
            ) {
                return true;
            }
            return false;
        },
    },

    watch: {
        isLoading: function setGlobalIsLoading() {
            this.$isLoading = false;
        },
    },

    methods: {
        constructFileUrl(path) {
            return constructUrlForStorage(path);
        },
        async getCustomizationFromAPI() {
            await getCustomization().then((result) => {
                this.$vuetify.theme.themes.light = result.data;
                this.isLoadingCustomization = false;
            });
        },
        async getTextsFromAPI() {
            await getTexts().then((result) => {
                this.$store.commit('setTexts', result.data);
                this.isLoadingTexts = false;
            });
        },
        async getAuth(endRedirect) {
            await auth(this.$store.state.session).then((result) => {
                this.$store.commit('setAuth', result.data);
                if (!this.$store.state.auth.auth && !endRedirect) {
                    window.location.href = `${process.env.VUE_APP_SPROGTRAPPEN_URL}/redirectToVideoPlatform`;
                }
                this.isLoadingAuth = false;
            });
        },
        async getSettingsFromAPI() {
            await getSettings().then((result) => {
                this.$store.commit('setSettings', result.data);
                this.isLoadingSettings = false;
            });
        },
    },
};
</script>

<style lang='scss'>
.v-application {
   font-family: "Circe-rounded", sans-serif !important;
 }

.appbarStyle {
    background-color: var(--v-appbar_background-base) !important;
}

.cookieButton {
    border-radius: 25px;
    color: #29324a;
    background-color: #f4d240;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}

p {
    font-family: "Circe-rounded", sans-serif;
    font-size: 16px;
    font-weight: 500;
    color: #29324a;
}

h1 {
    font-family: "Circe-rounded", sans-serif;
    font-size: 40px;
    font-weight: 500;
}

h2 {
    font-family: "Circe-rounded", sans-serif;
    font-size: 25px;
    font-weight: 500;
}
h3 {
    font-family: "Circe-rounded", sans-serif;
    font-size: 20px;
    font-weight: 500;
}
h4 {
    font-family: "Circe-rounded", sans-serif;
    font-size: 14px;
    font-weight: 500;
}
</style>
<style scoped >
.menu-button {
    text-transform: none;
    font-family: "Circe rounded", sans-serif;
    font-weight: 600;
    letter-spacing: 0px;
    color: var(--v-appbar_link-base) !important;
    font-size: 16px !important;
}

.menu-button-solid {
    text-transform: none;
    font-family: "Circe rounded", sans-serif;
    font-weight: 600;
    letter-spacing: 0px;
    color: white !important;
    font-size: 16px !important;
    background-color: var(--v-button-base)
}

.app-size {
    max-width: 1700px;
    margin: 0 auto;
}
</style>
