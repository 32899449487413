import Vue from 'vue';
import Vuex from 'vuex';
import VueCookies from 'vue-cookies';
import VuexPersist from 'vuex-persist';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
    key: 'vuex',
    storage: window.localStorage,
});

const vuexStore = new Vuex.Store({
    state: {
        texts: [],
        settings: [],
        auth: false,
    },
    plugins: [vuexLocalStorage.plugin],
    mutations: {
        setTexts(state, texts) {
            state.texts = texts;
        },
        setSettings(state, settings) {
            state.settings = settings;
        },
        setAuth(state, auth) {
            state.auth = auth;
        },
        setSession(state, session) {
            state.session = session;
        },
    },
});

new Vue({
    vuetify,
    store: vuexStore,
    router,
    render: (h) => h(App),

}).$mount('#app');
