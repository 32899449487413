function constructUrlForStorage(path) {
    return `${process.env.VUE_APP_STORAGE_URL}${path}`;
}

function constructUrlForAPI1(path) {
    return `${process.env.VUE_APP_API_URL}${path}`;
}

export {
    constructUrlForStorage, constructUrlForAPI1,
};
