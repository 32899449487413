import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/:redirect',
        name: 'Home',
        component: Home,
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
    },

    {
        path: '/home',
        name: 'landing_page',
        component: () => import('../views/LandingPage.vue'),
    },
    {
        path: '/municipality/:municipalityId',
        name: 'municipality_home',
        component: () => import('../views/MunicipalityHome.vue'),
        children: [
            {
                path: 'course/:courseId',
                name: 'course',
                component: () => import('../views/Course.vue'),
                children: [
                    {
                        path: ':chapterId',
                        name: 'chapter',
                        component: () => import('../views/Chapter.vue'),
                    },
                ],
            },
        ],

    },

];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;

// const routes = [
//     {
//         path: '/:redirect',
//         name: 'Home',
//         component: Home,
//     },
//     {
//         path: '/',
//         name: 'Home',
//         component: Home,
//     },
//     {
//         path: '/municipality/:municipalityId',
//         name: 'municipality_home',
//         component: () => import('../views/MunicipalityHome.vue'),
//     },
//     {
//         path: '/course/:id',
//         name: 'course',
//         component: () => import('../views/Course.vue'),
//         children: [
//             { path: 'chapter/:chapterId', name: 'chapter',
//  component: () => import('../views/Chapter.vue') },
//         ],
//     },
// ];
